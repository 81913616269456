<template>
  <div class="ui-game__foot">
    <canvas ref="canvas" width="160" height="320"></canvas>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { Foot } from '@/enums';
import { hexToColor } from '@/utils';

const data = [
  "00:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "01:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "02:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "03:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "04:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "05:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "06:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "07:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "08:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "09:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0A:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0B:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0C:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0D:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0E:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0F:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "10:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "11:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "12:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "13:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "14:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "15:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "16:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "17:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "18:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "19:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1A:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1B:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1C:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1D:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1E:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1F:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
];

const canvas = ref(null);

const parse = (value) => {
  const [rowInfo] = value.split(':');
  const currentRowIndex = data.findIndex((record) => record.split(':')[0] === rowInfo);

  if (currentRowIndex !== -1) {
    data.splice(currentRowIndex, 1, value);
  }

  render(data);
};

const render = (data) => {
  const ctx = canvas.value.getContext('2d');

  data.forEach((record) => {
    const [rowInfo, , sensorValues] = record.split(':');
    const row = parseInt(rowInfo, 16);
    const str = sensorValues.replace(/\s/g, '');
    const values = str.match(/.{1,2}/g);

    for (let column = 0; column < Foot.COLUMNS; column++) {
      const sensorValue = values[column];

      ctx.fillStyle = hexToColor(sensorValue);
      ctx.fillRect(column * Foot.CELL_SIZE, row * Foot.CELL_SIZE, Foot.CELL_SIZE, Foot.CELL_SIZE);
    }
  });
};

onMounted(() => {
  render(data);

  window.btReceived = parse;
});
</script>
