<template>
  <div ref="container" class="ui-game" @click="onClick" @keydown.space="onClick" tabindex="0">
    <canvas ref="canvas" :width="width" :height="height"></canvas>
    <ui-game-foot />
    <ui-game-score />
    <ui-game-sound />
    <ui-game-info
      @next="onNext"
      @replay="onReplay"
      @resume="onResume"
      @start="onStart"
    />
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { GameType } from '@/enums';
import UiGameFoot from '@/components/ui-game-foot/index.vue';
import UiGameInfo from '@/components/ui-game-info/index.vue';
import UiGameSound from '@/components/ui-game-sound/index.vue';
import UiGameScore from '@/components/ui-game-score/index.vue';
import {
  store, setStoreMovement, setStoreData, setStoreLevel, setStoreState, resetStoreMovement, resetStoreState,
} from '@/components/ui-game/store';
import Game from '@/libs/game';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

setStoreData({ ...props.data });
setStoreLevel(props.data.current_level || 0);
setStoreState({
  level: store.data[store.level],
  score: props.data.score || 0,
  killPenalty: props.data.killPenalty || 1,
});

const container = ref(null);
const canvas = ref(null);
const width = ref(0);
const height = ref(0);
const game = ref(null);

const onUpdateState = (newState) => setStoreState(newState);

const onDestroy = () => {
  game.value?.destroy();
  game.value = null;
};

const onReset = () => {
  resetStoreMovement();
  resetStoreState();
};

const onStart = () => {
  onDestroy();
  onReset();

  const { offsetWidth, offsetHeight } = container.value;

  width.value = offsetWidth;
  height.value = offsetHeight;

  game.value = new Game({
    $el: canvas.value,
    width: offsetWidth,
    height: offsetHeight,
    onUpdate: onUpdateState,
    state: store.state,
  });
};

const onReplay = () => onStart();

const onNext = () => {
  if (store.data.levels[store.level + 1]) {
    setStoreLevel(store.level + 1);
    onStart();
  }
};

const onResume = () => game.value?.play();

const onKeyPressed = (key, state) => {
  if (!store.state.isPlaying) {
    return;
  }

  const { astropaw_game_type, astropaw_alternate_game_type } = store.data || {};
  const gameType = Array.isArray(astropaw_game_type) ? astropaw_game_type : [astropaw_game_type];
  const alternateGameType = Array.isArray(astropaw_alternate_game_type) ? astropaw_alternate_game_type : [astropaw_alternate_game_type];

  console.log('keyPressed', 'key', key, 'state', state);
  if (state) {
    setStoreMovement({ allMovements: store.movement.allMovements + 1 });

    if ([...gameType, ...alternateGameType].includes(key)) {
      game.value?.click();
      setStoreMovement({ correctMovements: store.movement.correctMovements + 1 });
    }
  }
};

const onClick = () => {
  if (!store.state.isPlaying) {
    return;
  }

  const { astropaw_game_type, astropaw_alternate_game_type } = store.data || {};
  const gameType = Array.isArray(astropaw_game_type) ? astropaw_game_type : [astropaw_game_type];
  const alternateGameType = Array.isArray(astropaw_alternate_game_type) ? astropaw_alternate_game_type : [astropaw_alternate_game_type];

  if ([...gameType, ...alternateGameType].includes(GameType.ENTER)) {
    game.value?.click();
    setStoreMovement({ alternateMovements: store.movement.alternateMovements + 1 });
  }
};

onMounted(() => {
  window.keyPressed = onKeyPressed;
});
</script>
