import { Game } from '@/enums';

export default class Background {
  constructor({ context, width, height, state, onEnd }) {
    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.state = state;
    this.speed = Game.BASE_SPEED;
    this.onEnd = onEnd;
    this.images = [];
    this.data = { x: 0, y: 0 };
    this.floor = { x: 0, y: 0 };

    this.create();
  }

  create() {
    this.createBackground();
    this.createFloor();
  }

  createBackground() {
    const { background } = this.state.level;
    const images = Array.isArray(background) ? background : [background];
    this.images = images.map((src, index) => {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        const { width: imageWidth, height: imageHeight } = image;
        const apectWidth = this.canvasWidth / imageWidth;
        const aspectHeight = this.canvasHeight / imageHeight;
        const aspect = Math.max(apectWidth, aspectHeight);
        const width = imageWidth;
        const height = imageHeight;

        Object.assign(this.images[index], {
          width,
          height,
          aspect,
        });

        Object.assign(this.data, {
          width,
          height,
          aspect,
        });
      };

      return { image };
    });
  }

  createFloor() {
    const image = new Image();
    image.src = this.state.level.floor;
    image.onload = () => {
      const { width: imageWidth, height: imageHeight } = image;
      const aspectHeight = this.canvasHeight / imageHeight;
      const aspect = aspectHeight;
      const width = imageWidth;
      const height = imageHeight;
      const gameAreaSize = Math.max(this.canvasHeight - height, Game.GAME_AREA_SIZE_MULTIPLIER * this.canvasHeight);

      this.floor = { image, x: 0, y: gameAreaSize, width, height, aspect };
    };
  }

  render({ state }) {
    this.state = state;

    this.renderBackground();
    this.renderFloor();
    this.move();
  }

  renderBackground() {
    this.images.forEach((img, index) => {
      const { image, width, height, aspect } = img;
      const { x, y } = this.data;

      this.context.drawImage(
        image,
        0, 0, width, height,
        x + (index * width * aspect), y, width * aspect, height * aspect,
      );
    });
  }

  renderFloor() {
    const { image, x, y, width, aspect } = this.floor;

    if (!y) {
      return;
    }

    let index = 0;
    while (index * width <= 2 * this.data.width) {
      this.context.drawImage(
        image,
        0, 0, width, (this.canvasHeight - y) * aspect,
        index * width + x, y, width, (this.canvasHeight - y) * aspect,
      );
      index++;
    }
  }

  reset() {
    this.data.x = 0;
    this.data.y = 0;
  }

  move() {
    if (!this.state.isPlaying) {
      return;
    }

    this.data.x -= this.speed;
    this.floor.x -= (this.speed + 1);
  }
}
